<script>
  import appConfig from '../../../../src/app.config'
  import Layout from '../../layouts/main'
  import PageHeader from '@/components/page-header'
  import Preloader from "@/components/widgets/preloader";
  import Multiselect from 'vue-multiselect'
  import {email, minLength, required} from 'vuelidate/lib/validators'
  import Vue from "vue";

  import Repository from "../../../app/repository/repository-factory";
  import {AuthJwtService} from "../../../app/service/auth/auth-jwt-service";

  const UserAccountRepository = Repository.get("UserAccountRepository");

/**
 * Form validation component
 */
export default {
  page: {
    title: '',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, PageHeader, Preloader, Multiselect},
  data() {
    return {
      isGrantedAdd: false,
      inProgress: false,
      preloader: true,
      title: 'Dodaj pracownika',
      items: [
        {
          text: 'Pracownicy',
          to: { name: 'Lista pracowników' }
        },
        {
          text: 'Dodaj',
          active: true,
        },
      ],
      form: {
        firstName: '',
        lastName: '',
        email: '',
        position: '',
        isActive: true,
        roles: '',
        profileImageUrl: ''
      },
      rolesSelectOptions: [
        {value: 'ROLE_EMPLOYEE', text: 'Pracownik'},
        {value: 'ROLE_ADMIN', text: 'Administrator'}
      ],
      submitted: false,
    };
  },
  validations: {
    form: {
      firstName: {required, minLength: minLength(3)},
      lastName: {required, minLength: minLength(3)},
      email: {required, email},
      position: {required, minLength: minLength(3)},
      roles: {required},
      profileImageUrl: {}
    }
  },
  created() {
    this.isAdmin();
    this.isSuperAdmin();

    if (this.isGrantedEdit === false) {
      this.$router.push({ name: "500"})
    }

    this.preloader = false;
  },
  methods: {
    isAdmin() {
      if (AuthJwtService.isGranted('ROLE_ADMIN') === true) {
        this.isGrantedAdd = true;
      }

    },
    isSuperAdmin() {
      if (AuthJwtService.isGranted('ROLE_SUPER_ADMIN') === true) {
        this.isGrantedAdd = true;
      }
    },
    addUserAccount() {
      this.formSubmit();

      if (this.$v.$error === true) {
        return false;
      }

      let i;
      let roles = [];
      for (i = 0; i < this.form.roles.length; i++) {
        roles.push(this.form.roles[i].value);
      }

      let payload = Object.assign({}, this.form);
      payload.roles = roles;

      UserAccountRepository.create(payload).then(() => {
        Vue.swal({
          icon: "success",
          toast: false,
          position: 'top',
          title: "SUKCES! ",
          text: 'Użytkownik został dodany!',
          showConfirmButton: false,
          timer: 2000,
          onClose: () => {
            this.$router.push({ name: "Lista pracowników"})
          }
        });
      }).catch(error => {
        Vue.swal({
          icon: "error",
          position: 'top',
          title: "Wystąpił błąd. Spróbuj jeszcze raz!",
          text: error.response.data.message,
          showConfirmButton: true
        });
      })
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch()
    },
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <preloader v-if="preloader"></preloader>

    <div class="row">
      <div class="col-lg-12 col-xl-8">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Dodaj pracownika</h4>
            <p class="card-title-desc">
              Tutaj możesz utworzyć nowego użytkownika.
            </p>
            <b-form @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group required">
                    <label for="firstName">Imię:</label>
                    <input
                            id="firstName"
                            v-model="form.firstName"
                            type="text"
                            class="form-control"
                            placeholder="Imię"
                            value=""
                            :class="{ 'is-invalid': submitted && $v.form.firstName.$error }"
                    />
                    <div v-if="submitted && $v.form.firstName.$error" class="invalid-feedback">
                      <span v-if="!$v.form.firstName.required">Ta wartość jest wymagana.<br></span>
                      <span v-if="!$v.form.firstName.minLength">Ta wartość musi być dłuższa niż 2 znak.<br></span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group required">
                    <label for="firstName">Nazwisko:</label>
                    <input
                            id="lastName"
                            v-model="form.lastName"
                            type="text"
                            class="form-control"
                            placeholder="Nazwisko"
                            value=""
                            :class="{ 'is-invalid': submitted && $v.form.lastName.$error }"
                    />
                    <div v-if="submitted && $v.form.lastName.$error" class="invalid-feedback">
                      <span v-if="!$v.form.lastName.required">Ta wartość jest wymagana.<br></span>
                      <span v-if="!$v.form.lastName.minLength">Ta wartość musi być dłuższa niż 2 znak.<br></span>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group required">
                    <label for="firstName">E-mail:</label>
                    <input
                            id="email"
                            v-model="form.email"
                            type="text"
                            class="form-control"
                            placeholder="Email"
                            value=""
                            :class="{ 'is-invalid': submitted && $v.form.email.$error }"
                    />
                    <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                      <span v-if="!$v.form.email.required">Ta wartość jest wymagana.<br></span>
                      <span v-if="!$v.form.email.email">Ta wartość musi być poprawnym adresem e-mail.<br></span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group required">
                    <label for="firstName">Stanowisko:</label>
                    <input
                            id="position"
                            v-model="form.position"
                            type="text"
                            class="form-control"
                            placeholder="Stanowisko"
                            value=""
                            :class="{ 'is-invalid': submitted && $v.form.position.$error }"
                    />
                    <div v-if="submitted && $v.form.position.$error" class="invalid-feedback">
                      <span v-if="!$v.form.position.required">Ta wartość jest wymagana.<br></span>
                      <span v-if="!$v.form.position.minLength">Ta wartość musi być dłuższa niż 2 znak.<br></span>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="profileImageUrl">URL zdjęcia profilowego:</label>
                    <input
                            id="profileImageUrl"
                            v-model="form.profileImageUrl"
                            type="text"
                            class="form-control"
                            placeholder="URL zdjęcia profilowego"
                            value=""
                            :class="{ 'is-invalid': submitted && $v.form.profileImageUrl.$error }"
                    />
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group required">
                    <label for="firstName">Rola:</label>
                    <multiselect :class="{ 'is-invalid': submitted && $v.form.roles.$error }"
                                 :multiple="true"
                                 v-model="form.roles"
                                 :options="rolesSelectOptions"
                                 :optionsLimit=1000
                                 :preserve-search="true"
                                 track-by="value"
                                 label="text"
                                 placeholder="Wybierz"
                                 selectLabel=""
                                 deselectLabel="Odznacz"
                                 selectedLabel="Wybrane">

                      <template v-slot:noResult>
                        Brak rezultatów.
                      </template>

                    </multiselect>
                    <div v-if="submitted && $v.form.roles.$error" class="invalid-feedback">
                      <span v-if="!$v.form.roles.required">Ta wartość jest wymagana.<br></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input
                              id="isActive"
                              v-model="form.isActive"
                              type="checkbox"
                              class="custom-control-input"/>
                      <label
                              class="custom-control-label"
                              for="isActive">Konto aktywne?</label>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" v-on:click="addUserAccount" :disabled="this.$v.$error" type="submit">
                Dodaj użytkownika
              </button>
            </b-form>
          </div>
        </div>
      </div>
      <div class="col-lg-auto col-xl-4">
        <div class="card">
          <div class="card-body">
            <img src="@/assets/images/vector_volunteers0.png" alt class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
